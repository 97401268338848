import * as React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "./index.scss";
import { Box, TableSortLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getFullLoginHistory } from "../../../../../redux/loginHistory/loginHistorySlice";

function descendingComparator(a, b, orderBy) {
  if (
    orderBy === "amount" ||
    orderBy === "beforeTransaction" ||
    orderBy === "afterTransaction" ||
    orderBy === "createdAt" ||
    orderBy === "id"
  ) {
    if (+b[orderBy] < +a[orderBy]) {
      return -1;
    }
    if (+b[orderBy] > +a[orderBy]) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => {
    return [el, index];
  });
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  { id: "id", label: "Id", disablePadding: false },
  { id: "createdAt", label: "Login date", disablePadding: false },
  { id: "deviceType", label: "Device type", disablePadding: false },
  { id: "ipAddress", label: "IP Address", disablePadding: false },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  // setInterval(() => {
  //   dispatch(getAllRegisteredUsers());
  // }, 10000);
  return (
    <TableHead style={{ background: "#3E4396" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ textWrap: "nowrap" }}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function LoginHistoryTable({ user }) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");

  const rows = useSelector((state) => state.loginHistory.loginHistory);
  const users = useSelector((state) => state.users.users);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const rowsToShow = !!user
    ? rows?.filter((row) => row?.userId === user?.id)
    : rows;
  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  console.log(rowsPerPage);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsToShow?.length) : 0;

  React.useEffect(() => {
    dispatch(getFullLoginHistory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className="login-history-container-admin">
      {!user && <h1 className="login-history-header">Login History</h1>}
      {rowsToShow?.length > 0 ? (
        <Paper
          style={{
            width: !!user ? "70%" : "70%",
            maxHeight: "400px",
            overflowY: "auto",
            margin: !!user ? "0 auto" : "0 2% auto",
            marginBottom: !!user ? "1rem" : "0",
          }}
        >
          <TableContainer>
            <Table
              sx={{ minWidth: 750, background: "#1F2A40" }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rowsToShow?.length}
              />
              <TableBody style={{ background: "#1F2A40" }}>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rowsToShow, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const foundUser = users?.find(
                      (user) => user?.id === row?.userId
                    );

                    return (
                      <TableRow
                        hover={true}
                        role="checkbox"
                        tabIndex={-1}
                        key={row?.id}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.createdAt &&
                            new Date(row.createdAt).toLocaleString("sv-SE", {
                              timeZone: "Europe/Stockholm",
                            })}
                        </TableCell>
                        {/* <TableCell>{row.deviceType}</TableCell> */}
                        <TableCell>{row.ipAddress}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ background: "#3E4396" }}
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={rowsToShow?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <h3 style={{ color: "red" }}>No available login history...</h3>
      )}
    </div>
  );
}
